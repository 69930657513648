import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardModule } from '../../modules/dashboard/dashboard.module';
import { SharedModule } from '../../shared/shared.module';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forChild(routes), 
    DashboardModule,
    SharedModule
  ],
  exports: [
    RouterModule
  ]
})
export class PrivateRoutesRoutingModule { }
