import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../types/types';
import { AuthService } from './auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class ApiSharingService {

  currentUserObs : Subscription;

  private httpClient: HttpClient;
  
  constructor(handler: HttpBackend, private authService: AuthService) {
    this.httpClient = new HttpClient(handler);
  }

  public updateUserProfilePic( selectedImage){
    
    const formData = new FormData();
    formData.append('profilePicture', selectedImage);

    if( this.currentUserObs ){
      this.currentUserObs.unsubscribe();
    }
    this.currentUserObs = this.authService.getCurrentUser().subscribe(user => {
      formData.append('user',new Blob([JSON
        .stringify(user,[])], {
        type: 'application/json'
      }));
      
      //Bypass interceptor
      this.httpClient.post<User>(`${environment.serverUrl}/user/${user.id}`, formData, { observe: 'response',headers:{Authorization: `Bearer ${this.authService.authToken}`,'Access-Control-Allow-Origin': '*',} })
      .subscribe({
        next: (response) => {
          if( this.currentUserObs ){
            this.currentUserObs.unsubscribe();
          }
          this.authService.setUser(response.body)
        },
        error: (e) => {
          console.error(e);
          //Show alert
        }
      });
    });
  }
}
