import { Component, OnInit } from '@angular/core';
import { ListBaseComponent } from '../../../components/list-base/list-base.component';
import { QRCodeBatch } from '../../../types/types';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../../app.service';
import { AddQrCodeBatchComponent } from '../add-qr-code-batch/add-qr-code-batch.component'
import { debounceTime, Subject, Subscription } from 'rxjs';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
@Component({
  selector: 'app-list-qr-code-batch',
  templateUrl: './list-qr-code-batch.component.html',
  styleUrls: ['./list-qr-code-batch.component.scss']
})
export class ListQrCodeBatchComponent extends ListBaseComponent<QRCodeBatch> implements OnInit {

  qrCodeRegistrationObserver: Subscription;

  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, protected appService: AppService,
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute, private modalService: NgbModal) {
      super(http,breadcrumbService,appService,offcanvasService,router,route); 
     }

  ngOnInit(): void {
    this.pageCallback({ offset: 0 });
  }

  ngOnDestroy(){
    if( this.qrCodeRegistrationObserver ){
      this.qrCodeRegistrationObserver.unsubscribe();
    }
  }
  addNewQRCodeBatch(){
    const modalRef = this.modalService.open(AddQrCodeBatchComponent,{ centered: true,size: 'xl' });
    modalRef.componentInstance.name = 'Add / QR Codes';
    
    if( this.qrCodeRegistrationObserver ){
      this.qrCodeRegistrationObserver.unsubscribe();
    }

    this.qrCodeRegistrationObserver = modalRef.componentInstance.qRCodeBatchEntry.subscribe((qrCodeBatch) => {
      this.reloadTable()
    });
  }

  onActivate(event){
    if(event.type == 'click') {
      console.log(event.row);
      const modalRef = this.modalService.open(AddQrCodeBatchComponent,{ centered: true,size: 'xl' });
      
      if( this.qrCodeRegistrationObserver ){
        this.qrCodeRegistrationObserver.unsubscribe();
      }
    
      modalRef.componentInstance.qRCodeBatch = event.row;

      this.qrCodeRegistrationObserver = modalRef.componentInstance.qRCodeBatchEntry.subscribe((contractor) => {
        this.reloadTable()
      });
    }
  }

  getRowClass = (row) => {    
    return {
      'row-used': row.used,
      'row-unused': !row.used,
    };
   }
   
  protected get loadingUrl(){
    return `${environment.serverUrl}/qrcode-batch`;
  }

  protected get exportUrl(){
    return `${environment.serverUrl}/qrcode-batch/export`;
  }

  protected get exportFileName(){
    return `Products Report`;
  }

}
