import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { map, Observable, of, Subscription, tap } from 'rxjs';
import { AuthService } from '../services/auth-service.service';
import { User } from '../types/types';

@Injectable({
  providedIn: 'root'
})
export class FlytAuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {

  user : User;
  userSub: Subscription;
  
  constructor(private authService: AuthService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      let url: string = state.url;
      return this.authService.getCurrentUser().pipe(
        map(user => {
          const isLogged = !!user;
          if( isLogged ){
            const userRole = user?.role;
            if (route.data.role && route.data.role.indexOf(userRole) === -1) {
              console.log('Logged in user doesnt have access to the requested resource');
              this.router.navigate(['/error/401']);
              return false;
            }
            return true;
          }
          else{
            this.router.navigate(['auth/login']);
          }
        })
      );
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      return this.canActivate(childRoute, state);
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
