import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject, from, merge } from "rxjs";
import { map, scan } from "rxjs/operators";
import { Comment } from "../../types/types"
import { environment } from "../../../environments/environment";

export const data = [
  {
      username: "Kevin",
      date: "3 hours ago",
      text: "#Hello\n>quote\n\n`code`",
      votes: 12
  },
  {
    username: "Kevin",
    date: "3 hours ago",
    text: "#Hello\n>quote\n\n`code`",
    votes: 12,
    comments: [
      {
        username: "Kevin",
        date: "2 hours ago",
        text: "^ click the minimize button to hide threads",
        votes: 8,
        comments: [
          {
            username: "Kevin",
            date: "1 hours ago",
            text: "<- Click the arrows to vote",
            votes: 3,
            comments: []
          }
        ]
      },
      {
        username: "Kevin",
        date: "4 hours ago",
        text: "click on reply to open up a text prompt",
        votes: 5,
        comments: []
      },
      {
        username: "Kevin",
        date: "4 hours ago",
        text: "click on reply to open up a text prompt",
        votes: 5,
        comments: []
      },
      {
        username: "Kevin",
        date: "4 hours ago",
        text: "click on reply to open up a text prompt",
        votes: 5,
        comments: []
      },
      {
        username: "Kevin",
        date: "10 mins ago",
        text: "this",
        votes: 2,
        comments: [
          {
            username: "Kevin",
            date: "8 mins ago",
            text: "is",
            votes: 1,
            comments: [
              {
                username: "Kevin",
                date: "5 mins ago",
                text: "to",
                votes: 0,
                comments: [
                  {
                    username: "Kevin",
                    date: "4 mins ago",
                    text: "show",
                    votes: -1,
                    comments: [
                      {
                        username: "Kevin",
                        date: "2 mins ago",
                        text: "nesting",
                        votes: -200,
                        comments: []
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
]

@Injectable({
  providedIn: "root"
})

export class CommentsService {

  src: any = data;
  commentAction$ = new Subject<Comment>();
  commentSubject$ = this.commentAction$.asObservable();
  commentStream$ = merge(this.commentSubject$, from([data])).pipe(
    scan((acc: Comment[], value: Comment) => [value, ...acc])
  );
  constructor(private httpClient: HttpClient) {}

  getComments(type: string, postId:number) : Observable<Comment[]>{
    return this.httpClient.get<Comment[]>(`${environment.serverUrl}/comments/${type}/${postId}`)
  }

  addComment(comment: Comment) {
    this.commentAction$.next(comment);
  }
}
