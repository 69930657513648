import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListRedeemRequestsComponent } from './list-redeem-requests/list-redeem-requests.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared/shared.module';
import { RedeemRequestDetailsComponent } from './redeem-request-details/redeem-request-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomerModule } from '../customer/customer.module'
@NgModule({
  declarations: [
    ListRedeemRequestsComponent,
    RedeemRequestDetailsComponent
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule,
    CKEditorModule,
    NgbDatepickerModule,
    NgbModule,
    CustomerModule
  ]
})
export class RedeemRequestModule { }
