import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth-service.service';
import { IS_UPLOAD_REQUEST } from '../types/types';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService) {} 

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.authToken;

    if (token) {
      // If we have a token, we set it to the header
      if (request.context.get(IS_UPLOAD_REQUEST)){
        request = request.clone({
          setHeaders: { Authorization: `${token}`,'Access-Control-Allow-Origin': '*',"Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE" },
        });
      }
      else{
        request = request.clone({
          setHeaders: { Authorization: `${token}`,'Access-Control-Allow-Origin': '*','Content-Type':'application/json',"Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE" },
          
        });
      }
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 ) {
            // redirect user to the login page
            this.authService.redirectToLogin('auth/login','');
          }
        }
        return throwError(err);
      })
    )
  }
}