<div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Closed')"></button>
</div>
<form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="card-body">
        <div *ngIf="product == undefined else edit_product" ><div class="ribbon ribbon-primary">Add New Product</div></div>
        <ng-template #edit_product><div class="ribbon ribbon-primary">Edit Product Details</div></ng-template>
        <div class="row">
            <div class="col-sm-12 col-xl-9 form-group m-form__group">
                <div class="card shadow shadow-showcase">
                    <ngb-alert *ngIf="alertError" class="fade animate-show animate-hide" [type]="'danger'" (closed)="alertError = false"><strong>{{ message }}</strong></ngb-alert>
                    <div class="card-body row" style="margin-top:30px;">
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label >Product Name</label>
                            <span class="text-danger">*</span>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="name" type="text" class="form-control" placeholder="Name of the Product" required>
                            </div>
                            <div *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched)" class="text text-danger">
                                <div *ngIf="form.controls.name.errors.required">
                                    Product Name is required
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label >Category</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <select  formControlName="categoryId" class="form-control digits">  
                                    <option value="0">--Select--</option>  
                                    <option *ngFor="let category of categories"  
                                            value={{category.id}}>  
                                        {{category.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label >Attribute 1 </label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="attr1" type="text" class="form-control" placeholder="Attribute 1">
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label >Attribute 2 </label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="attr2" type="text" class="form-control" placeholder="Attribute 2">
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label >Attribute 3 </label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="attr3" type="text" class="form-control" placeholder="Attribute 3">
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label >Attribute 4 </label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="attr4" type="text" class="form-control" placeholder="Attribute 4">
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label >Attribute 5 </label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="attr5" type="text" class="form-control" placeholder="Attribute 5">
                            </div>
                        </div>
                        <div class="col-sm-12 col-xl-12 form-group m-form__group">
                            <label >Commission Amount</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="commission" type="number" class="form-control" placeholder="Commission Amount">
                            </div>
                        </div>

                        <div class="col-sm-12 col-xl-12 form-group m-form__group">
                            <label >Description</label>
                            <span class="text-danger">*</span>
                            <ckeditor formControlName="description" [editor]="ClassicEditor"></ckeditor>
                            <div *ngIf="form.controls.description.invalid && (form.controls.description.dirty || form.controls.description.touched)" class="text text-danger">
                                <div *ngIf="form.controls.description.errors.required">
                                    Description is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer" style="text-align:right;">
                        <button type="submit" class="btn btn-primary me-1">Save Product Details</button>
                        <button type="button" class="btn btn-light" (click)="activeModal.dismiss('Closed')">Cancel</button>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-xl-3 form-group m-form__group">
                <div class="card shadow shadow-showcase">
                    <div class="card-header" style="padding-bottom:10px;border-bottom:none;"><h5>Images / Tech Sheet</h5></div>
                    <div class="card-body row">
                        <div class="col-sm-12 col-xl-12 form-group m-form__group">
                            <input #attachments id="productImage" type="file" (change)="addImage($event)" style="display: none;" accept="image/*"/>
                            <button type="button" class="btn btn-outline-primary m-t-10 m-b-10" onclick="document.getElementById('productImage').click();">
                                <i class="p-r-10 fa fa-plus"></i>
                                <span *ngIf="imageSrc"> Update Product Image </span>
                                <span *ngIf="!imageSrc"> Add Product Image </span>
                            </button>
                            <div *ngIf="imageSrc" class="file-asset">
                                <img style="max-width:200px" [src]="imageSrc" alt="Product Image" />
                                <span><strong>{{selectedImage?.name}}</strong></span>
                                <div class="remove-file" (click)="removeImage()"><a href="javascript:void(0)"><i style="font-size: 22px;color:#ff5370 !important" class="fa fa-trash-o"></i></a></div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-xl-12 form-group m-form__group">
                            <input #attachments id="productTechSheet" type="file" (change)="addTechSheet($event)" style="display: none;" accept="application/pdf"/>
                            <button type="button" class="btn btn-outline-primary m-t-10 m-b-10" onclick="document.getElementById('productTechSheet').click();">
                                <i class="p-r-10 fa fa-plus"></i>
                                <span *ngIf="techSheetAvaialble"> Update Technical Sheet </span>
                                <span *ngIf="!techSheetAvaialble"> Add Technical Sheet </span>
                            </button>
                            <div *ngIf="techSheetAvaialble && selectedTechSheet" style="max-width:130px !important"  class="file-asset">
                                <img style="max-width:100px" src="/assets/images/PDF_file_icon.png" alt="Product Image" />
                                <span><strong>{{selectedTechSheet.name}}</strong></span>
                                <div class="remove-file" (click)="removeTechSheet()"><a href="javascript:void(0)"><i style="font-size: 22px;color:#ff5370 !important" class="fa fa-trash-o"></i></a></div>
                            </div>
                            <div *ngIf="techSheetAvaialble && !selectedTechSheet" style="max-width:130px !important"  class="file-asset">
                                <a href="{{techSheetUrl}}" target="_blank"><img style="max-width:100px" src="/assets/images/PDF_file_icon.png" alt="Product Image" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>