import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Customer } from '../../../types/types';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-add-contractor',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit {

  customer: Customer = undefined;

  @Output() contractorEntry: EventEmitter<any> = new EventEmitter();

  form: FormGroup;

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  message : string = '';
  
  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder,private http: HttpClient,private toastrService: ToastrService) { }

  ngOnInit(): void {
    ["id", "name","mobileNumber","bankAccountNumber","ifscCode","upiId","verified","createdAt","updatedAt"]
    if( this.customer ){
      this.form = this.fb.group({
        id:this.customer.id,
        name: [this.customer.name],
        mobileNumber: [this.customer.mobileNumber, [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10)]],
        bankAccountNumber: [this.customer.bankAccountNumber, [Validators.required,Validators.minLength(5), Validators.maxLength(25)]],
        ifscCode: [this.customer.ifscCode, [Validators.required,Validators.minLength(5), Validators.maxLength(20)]],
        upiId: [this.customer.upiId],
        verified: [this.customer.verified],
        internalCode: [this.customer.internalCode],
      });
    }
    else{
      this.form = this.fb.group({
        name: [''],
        mobileNumber: ['', [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10)]],
        bankAccountNumber: ['', [Validators.required,Validators.minLength(5), Validators.maxLength(25)]],
        ifscCode: ['', [Validators.required,Validators.minLength(5), Validators.maxLength(20)]],
        upiId: [''],
        verified: [false],
        internalCode: [''],
      });
    }

  }

  onSubmit(){
  
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    let postUrl = `${environment.serverUrl}/customer`
    if( this.form.value.id != undefined){
      postUrl = `${environment.serverUrl}/customer/${this.form.value.id}`
    }
    
    this.http.post(postUrl, JSON.stringify(this.form.value))
        .subscribe({
          next: (v) => {
            console.log(v)
            this.activeModal.close('Success')
            if( this.form.value.id ){
              this.toastrService.success('Contractor details updated successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
            }
            else{
              this.toastrService.success('Contractor added successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
            }

            this.contractorEntry.emit(v);
          },
          error: (e) => {
            console.error(e);
            if( this.form.value.id ){
              this.showMessage("error",`Updating Contractor details failed. Message:${e.message}`);
            }
            else{
              this.showMessage("error",`Adding new Contractor failed. Message:${e.message}`);
            }
          },
          complete: () => console.info('complete')
        });
  }

  showMessage(type, message){
    if( type == "error" ){
      this.alertError = true;
    }
    this._message.next(message);
  }

  numberOnly(event): boolean {   
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
}

}
