import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';
declare var require
const Swal = require('sweetalert2')

@Injectable({
  providedIn: 'root'
})
export class ConfirmationGuard implements CanDeactivate<any> {

  constructor( private router: Router){}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if( nextState.url == '/auth/login'){
        return true;
      }
      if (component?.isFormDirty) {
        const subject = new Subject<boolean>();
        Swal.fire({
          title: 'Are you sure?',
          text: "You have unsaved changes in the form. Do you really want to leave this page?  ",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          subject.next(result.value);
        })
        return subject.asObservable();
      }
    return true;   
  }
  
}
