import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { User } from '../../../types/types';
import { environment } from '../../../../environments/environment';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  host: {
    '[style.overflow]': '"auto"'
  },
})
export class UserProfileComponent implements OnInit {

  environment = environment
  userId : Number;
  user : User;
  
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    if( this.userId ){
      this.http.get<User>(`${environment.serverUrl}/user/${this.userId}`)
      .subscribe({
        next: (user) => {
          this.user = user;
        },
        error: (e) => {
          console.error(e);
        }
      });
    }
  }
}
