<div class="modal-body ">
    <div class="card custom-card">
        <div class="card-header"><img class="img-fluid" [src]="user?.bannerPicture ? '{{environment.serverUrl}}/user/profile-pic/{{user?.id}}' : 'assets/images/user/default-banner.jpg'" alt=""></div>
        <div class="card-profile"><img class="rounded-circle" src="{{environment.serverUrl}}/user/profile-pic/{{user?.id}}" alt=""></div>
        <div class="text-center profile-details">
        <h4>{{user?.name}}</h4>
        <h6>{{user?.role}}</h6>
        </div>
        <div class="row" style="text-align: center;">
            <div class="col-4 col-sm-4">
                <h6>Staff ID</h6>
                <h3 class="counter">{{user?.staffId}}</h3>
            </div>
            <div class="col-4 col-sm-4">
                <h6>Ongoing Investigations(#)</h6>
                <h3 class="counter">11</h3>
            </div>
            <div class="col-4 col-sm-4">
                <h6>Assigned Tasks (#)</h6>
                <h3><span class="counter">26</span></h3>
            </div>
        </div>
        
    </div>
</div>