import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { trigger, transition, useAnimation, query, animate, style } from '@angular/animations';
import { bounce, zoomOut, zoomIn, fadeIn, bounceIn } from 'ng-animate';
import { NavService } from '../../../../services/nav-service.service';
import { CustomizerService } from '../../../services/customizer.service';
import { ActivatedRoute, Route, Router, RouterModule } from '@angular/router';
import * as feather from 'feather-icons';
import { isEmpty } from 'rxjs';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  animations: [
    
    trigger('animateRoute', [transition('* => *', useAnimation(fadeIn, {
      // Set the duration to 5seconds and delay to 2 seconds
      //params: { timing: 5}
    }))])
  ]
})
export class ContentLayoutComponent implements OnInit, AfterViewInit {


  public right_side_bar: boolean;

  constructor(public navServices: NavService,
    public customizer: CustomizerService, private readonly activatedRoute: ActivatedRoute,
    private router: Router ) { 
      let url = this.activatedRoute.snapshot.url.join().split(',')
      if( !url || url[0].length == 0 ){
        
      }
    }


  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  @HostListener('document:click', ['$event'])
  clickedOutside(event) {
    // click outside Area perform following action
    document.getElementById('outer-container').onclick = function (e) {
      e.stopPropagation()
      if (e.target != document.getElementById('search-outer')) {
        document.getElementsByTagName("body")[0].classList.remove("offcanvas");
      }
      if (e.target != document.getElementById('outer-container')) {
        document.getElementById("canvas-bookmark").classList.remove("offcanvas-bookmark");
      }
      if (e.target != document.getElementById('inner-customizer')) {
        document.getElementsByClassName("customizer-links")[0].classList.remove("open")
        document.getElementsByClassName("customizer-contain")[0].classList.remove("open")
      }
    }
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public rightSidebar($event) {
    this.right_side_bar = $event
  }
  
  ngOnInit() { }

}
