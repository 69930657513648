import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Menu } from '../../services/nav-service.service';
import { FlytAuthGuard } from '../../guards/flyt-auth-guard.guard';
import {Router, RouterModule} from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports:[
    RouterModule
  ]
})
export class AdminMenuModule { 

  constructor(private router: Router){}
  
  public static MENUITEMS: Menu[] = [
		  {
        title: 'Dashboard', icon: 'home', type: 'link', active: true,path: 'dashboard',
        route:{
          path: 'dashboard',
          data: {
            breadcrumb: {
              label: 'Dashboard',
            },
            role: 'ROLE_ADMIN',
            animation: 'slideInAnimation'
          },
          children: []
        },
      },
      {
        title: 'All Redeem Requests', icon: 'file-plus', type: 'link', active: false, path: 'redeem-requests',
        route:{
          children: [
            {
              pathMatch: 'full',
              data: {
                title:"All Redeem Requests",
                breadcrumb: {
                  label: "All Redeem Requests",
                  info: 'icofont icofont-files'
                },
                role: 'ROLE_ADMIN',
                animation: 'slideInAnimation'
              }
            },
            {
              data: {
                title:"Enquiry Details",
                breadcrumb: {
                  label: "Enquiry Details",
                  info: 'icofont icofont-files',
                },
                role: 'ROLE_ADMIN',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },
      {
        title: 'All QRCode Batches', icon: 'file-plus', type: 'link', active: false, path: 'qrcode-batches',
        route:{
          children: [
            {
              data: {
                title:"All QRCode Batches",
                breadcrumb: {
                  label: "All QRCode Batches",
                  info: 'icofont icofont-files'
                },
                role: 'ROLE_ADMIN',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },
      {
        title: 'All Customers', icon: 'file-plus', type: 'link', active: false, path: 'customers',
        route:{
          children: [
            {
              data: {
                title:"All Customers",
                breadcrumb: {
                  label: "All Customers",
                  info: 'icofont icofont-files'
                },
                role: 'ROLE_ADMIN',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },
      {
        title: 'All Products', icon: 'file-plus', type: 'link', active: false, path: 'products',
        route:{
          path: 'products',
          canActivate: [FlytAuthGuard],
          children: [
            {
              data: {
                title:"All Products",
                breadcrumb: {
                  label: "All Products",
                  info: 'icofont icofont-files'
                },
                role: 'ROLE_ADMIN',
                animation: 'slideInAnimation'
              }
            }
          ]
        }
      },

	]
}
