<div class="row">
    <div class="col-md-12 col-xl-12">
        <div class="card">
            <div class="card-header b-l-primary border-3">
                <button style="float: right;" type="button" class="btn btn-primary m-r-25" (click)="addNewProduct();$event.preventDefault()"><i class="p-r-5 fa fa-plus"></i><span><strong>Add New Product</strong></span></button>
            </div>
            <div class="card-body">
                <div style="padding-left:15px;padding-right:15px;">
                    <ngx-datatable
                        class="ngx-datatable content-table bootstrap fixed-header virtualized"
                        [loadingIndicator]="loading"
                        [columnMode]="'force'"
                        [headerHeight]="50"
                        [footerHeight]="50"
                        [rowHeight]="65"
                        [externalPaging]="true"
                        [externalSorting]="true"
                        [count]="page.count"
                        [offset]="page.offset"
                        [limit]="page.limit"
                        [sortType]="'single'"
                        [reorderable]="true"
                        (page)="pageCallback($event)"
                        (sort)="sortCallback($event)"
                        [sorts]="[{prop: 'id', dir: 'desc'}]"
                        (activate)="onActivate($event)"
                        [scrollbarH]="true"
                        [rows]="items"
                        >
                        <ngx-datatable-column prop="id" name="Sl. No" [width]="10" [resizeable]="false">
                            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <strong>{{row.id}}</strong>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column prop="name" name="Name" [width]="30" [resizeable]="true">
                            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <strong>{{row.name}}</strong>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column prop="description" name="Description" [width]="30" [resizeable]="true">
                            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <strong>{{row.description}}</strong>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column prop="createdAt" name="Created" [width]="30" [resizeable]="true">
                            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <strong>{{row.createdAt}}</strong>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column prop="updatedAt" name="Last Updated" [width]="30" [resizeable]="true">
                            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <strong>{{row.updatedAt}}</strong>
                            </ng-template>
                        </ngx-datatable-column>

                        
                    
                        <ngx-datatable-footer>
                            <ng-template
                              let-rowCount="rowCount"
                              let-pageSize="pageSize"
                              let-selectedCount="selectedCount"
                              let-curPage="curPage"
                              let-offset="offset"
                              ngx-datatable-footer-template
                            >
                            <div class="controls">
                                <div>
                                  <label class="datatable-body-cell-label " for="per-page">
                                    Items Per Page:
                                  </label>
                                  <select
                                    id="items-per-page" 
                                    class="form-control items-per-page"
                                    (change)="onLimitChange($event.target.value)"
                                    [value]="page.limit"
                                    style="display:inline-block;width: 50px;text-align: center;"
                                  >
                                    <option
                                      *ngFor="let option of pageLimitOptions"
                                      [value]="option.value"
                                    >
                                      {{ option.value }}
                                    </option>
                                  </select>
                                </div>
                            </div>
                              <app-datatable-pager
                                (download)="exportAs($event)"
                                [page]="curPage"
                                [visiblePagesCount]="3"
                                [size]="pageSize"
                                [count]="rowCount"
                                [hidden]="false"
                                (showFilter)="openFiltersSection(content)"
                                (clearFilter)="clearFilter()"
                                (change)="onPageChange($event)">
                              </app-datatable-pager>
                            </ng-template>
                          </ngx-datatable-footer>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
</div>
