import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Menu } from '../../services/nav-service.service';
import { FlytAuthGuard } from '../../guards/flyt-auth-guard.guard';
import { DefaultComponent } from '../../modules/dashboard/default/default.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class TeamMemberMenuModule { 

  public static MENUITEMS: Menu[] = [
		{
			title: 'Dashboard', icon: 'home', type: 'sub', badgeType: 'primary', badgeValue: 'new', active: false, 
        route:{
          path: 'dashboard',
          data: {
          breadcrumb: "Dashboard"
        }
      },
      children: [
				{ 
          path: '/dashboard/default', title: 'Default', type: 'link',
          route:{
            path: 'default',
            data: {
              title: "Default",
              breadcrumb: "Default",
              role: 'ROLE_USER,ROLE_ADMIN,ROLE_DIRECTOR,ROLE_NOTIFIER,ROLE_TEAM_MEMBER',
            },
            canActivate: [FlytAuthGuard],
            component: DefaultComponent,
          }
         }
			]
		},
	]
}
