  <!-- Container-fluid starts-->
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-8 xl-100">
        <div class="row">
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <div class="chart-widget-dashboard">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <h5 class="mt-0 mb-0 f-w-600"><span class="counter" [CountTo]="1234" [from]="0" [duration]="2"></span></h5>
                      <p>Total Bot Requests this month</p>
                    </div><app-feather-icons [icon]="'tag'"></app-feather-icons>
                  </div>
                  <div class="dashboard-chart-container">
                    <x-chartist class="small-chart-gradient-1" [data]="chart1.data" [type]="chart1.type" [options]="chart1.options" [events]="chart1.events">
                    </x-chartist>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <div class="chart-widget-dashboard">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <h5 class="mt-0 mb-0 f-w-600"><span class="counter" [CountTo]="1234" [from]="0" [duration]="2"></span></h5>
                      <p>Total Orders this month</p>
                    </div><app-feather-icons [icon]="'shopping-cart'"></app-feather-icons>
                  </div>
                  <div class="dashboard-chart-container">
                    <x-chartist class="small-chart-gradient-2" [data]="chart2.data" [type]="chart2.type" [options]="chart2.options" [events]="chart2.events">
                    </x-chartist>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <div class="chart-widget-dashboard">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <h5 class="mt-0 mb-0 f-w-600">Rs. <span class="counter" [CountTo]="8568" [from]="0" [duration]="2"></span></h5>
                      <p>Total Order Value</p>
                    </div>
                  </div>
                  <div class="dashboard-chart-container">
                    <x-chartist class="small-chart-gradient-3" [data]="chart3.data" [type]="chart3.type" [options]="chart3.options" [events]="chart3.events">
                    </x-chartist>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-xl-6">
            <div class="card">
              <div class="card-header card-header-border">
                <div class="row">
                  <div class="col-sm-6">
                    <h5>New Contractor</h5>
                  </div>
                  <div class="col-sm-6">
                    <div class="pull-right right-header"><span>Month</span><span>
                        <button class="btn btn-primary btn-pill">Today</button></span></div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="new-users">
                  <div class="d-flex"><img class="rounded-circle image-radius m-r-15" src="assets/images/user/2.png" alt="">
                    <div class="flex-grow-1">
                      <h6 class="mb-0 f-w-700">Nick Stone</h6>
                      <p>Visual Designer, Github Inc</p>
                    </div><span class="pull-right">
                      <button class="btn btn-pill btn-outline-light">Follow</button></span>
                  </div>
                  <div class="d-flex"><img class="rounded-circle image-radius m-r-15" src="assets/images/user/5.jpg" alt="">
                    <div class="flex-grow-1">
                      <h6 class="mb-0 f-w-700">Milano Esco</h6>
                      <p>Visual Designer, Github Inc</p>
                    </div><span class="pull-right">
                      <button class="btn btn-pill btn-outline-light">Follow</button></span>
                  </div>
                  <div class="d-flex"><img class="rounded-circle image-radius m-r-15" src="assets/images/user/3.jpg" alt="">
                    <div class="flex-grow-1">
                      <h6 class="mb-0 f-w-700">Wiltor Noice</h6>
                      <p>Visual Designer, Github Inc</p>
                    </div><span class="pull-right">
                      <button class="btn btn-pill btn-outline-light">Follow</button></span>
                  </div>
                  <div class="d-flex"><img class="rounded-circle image-radius m-r-15" src="assets/images/user/4.jpg" alt="">
                    <div class="flex-grow-1">
                      <h6 class="mb-0 f-w-700">Anna Strong</h6>
                      <p>Visual Designer, Github Inc</p>
                    </div><span class="pull-right">
                      <button class="btn btn-pill btn-outline-light">Follow</button></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6">
            <div class="card">
              <div class="card-header card-header-border">
                <div class="row">
                  <div class="col-sm-6">
                    <h5>Recent Activities</h5>
                  </div>
                  <div class="col-sm-6">
                    <div class="pull-right right-header">
                      <div class="onhover-dropdown">
                        <button class="btn btn-primary btn-pill" type="button">All <span class="pe-0"><i class="fa fa-angle-down"></i></span></button>
                        <div class="onhover-show-div right-header-dropdown"><a class="d-block"  href="javascript:void(0)">Order</a><a class="d-block"  href="javascript:void(0)">Download</a><a class="d-block"  href="javascript:void(0)">Trash</a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body recent-notification">
                <div class="d-flex">
                  <h6>09:00</h6>
                  <div class="flex-grow-1"><span>Lorem ipsum dolor sit amit,consectetur eiusmdd.</span>
                    <p class="f-12">By Kan</p>
                  </div>
                </div>
                <div class="d-flex">
                  <h6>10:50</h6>
                  <div class="flex-grow-1"><span>Lorem ipsum.</span>
                    <p class="f-12">By Tailer</p>
                  </div>
                </div>
                <div class="d-flex">
                  <h6>01:00</h6>
                  <div class="flex-grow-1"><span>Lorem ipsum dolor sit amit,consectetur eiusmdd.</span>
                    <p class="f-12">By Kaint</p>
                  </div>
                </div>
                <div class="d-flex">
                  <h6>05:00</h6>
                  <div class="flex-grow-1"><span>Lorem ipsum dolor sit amit.</span>
                    <p class="f-12">By call</p>
                  </div>
                </div>
                <div class="d-flex">
                  <h6>12:00</h6>
                  <div class="flex-grow-1"><span>Lorem ipsum dolor sit.</span>
                    <p class="f-12">By Waiter</p>
                  </div>
                </div>
                <div class="d-flex">
                  <h6>08:20</h6>
                  <div class="flex-grow-1"><span>Lorem ipsum dolor sit amit,consectetur eiusmdd dolor sit amit.</span>
                    <p class="f-12">By Comman</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-xl-4 xl-100">
        <div class="card">
          <div class="card-header">
            <h5>Activity</h5>
          </div>
          <div class="card-body activity-scroll">
            <div class="activity">
              <div class="d-flex mediaClass">
                <div class="gradient-round m-r-30 gradient-line-1"><app-feather-icons [icon]="'shopping-cart'"></app-feather-icons></div>
                <div class="flex-grow-1">
                  <h6>New Sale <span class="pull-right f-14">New</span></h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
                </div>
              </div>
              <div class="d-flex mediaClass">
                <div class="gradient-round m-r-30 gradient-line-1"><app-feather-icons [icon]="'message-circle'"></app-feather-icons></div>
                <div class="flex-grow-1">
                  <h6>New Message <span class="pull-right f-14">14m Ago</span></h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
                </div>
              </div>
              <div class="d-flex mediaClass">
                <div class="gradient-round m-r-30 small-line">
                  <app-feather-icons [icon]="'minus-circle'"></app-feather-icons></div>
                <div class="flex-grow-1">
                  <h6>New Report <span class="pull-right f-14">14m Ago</span></h6>
                  <p>Lorem Ipsum is simply dummy text.</p>
                </div>
              </div>
              <div class="d-flex mediaClass">
                <div class="gradient-round m-r-30 gradient-line-1"><app-feather-icons [icon]="'shopping-bag'"></app-feather-icons></div>
                <div class="flex-grow-1">
                  <h6>New Sale <span class="pull-right f-14">14m Ago</span></h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
                </div>
              </div>
              <div class="d-flex mediaClass">
                <div class="gradient-round m-r-30 medium-line"><app-feather-icons [icon]="'tag'"></app-feather-icons></div>
                <div class="flex-grow-1">
                  <h6>New Visits <span class="pull-right f-14">14m Ago</span></h6>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
      
      <div class="col-xl-7 xl-100">
        <div class="card">
          <div class="card-header card-header-border">
            <div class="row">
              <div class="col-sm-6">
                <h5>Most Recent Conversations</h5>
              </div>
              <div class="col-sm-6">
                <div class="right-header pull-right m-t-5">
                  <div class="onhover-dropdown"><app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
                    <div class="onhover-show-div right-header-dropdown more-dropdown"><a class="d-block"  href="javascript:void(0)">View</a><a class="d-block"  href="javascript:void(0)">Media</a><a class="d-block"  href="javascript:void(0)">Search</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body chat-box dashboard-chat">
            <div class="chat">
              <div class="media left-side-chat"><img class="rounded-circle chat-user-img img-60 m-r-20" src="assets/images/user/4.jpg" alt="">
                <div class="flex-grow-1">
                  <div class="message-main">
                    <p class="mb-0">Lorem Ipsum is simply dummy text of the </p>
                  </div>
                </div>
              </div>
              <div class="media right-side-chat">
                <div class="media-body text-end">
                  <div class="message-main">
                    <p class="pull-right">Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum has been the industry's</p>
                    <div class="clearfix"></div>
                  </div>
                  <div class="sub-message message-main">
                    <p class="pull-right mb-0">Lorem Ipsum is simply dummy text of the </p>
                    <div class="clearfix"></div>
                  </div>
                </div><img class="rounded-circle chat-user-img img-60 m-l-20" src="assets/images/user/5.jpg" alt="">
              </div>
              <div class="media left-side-chat"><img class="rounded-circle chat-user-img img-60 m-r-20" src="assets/images/user/4.jpg" alt="">
                <div class="flex-grow-1">
                  <div class="message-main">
                    <p>Lorem Ipsum is simply dummy text of the printing</p>
                  </div>
                  <div class="sub-message message-main smiley-bg"><img src="assets/images/dashboard/smily.png" alt=""></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
  <!-- Container-fluid Ends-->
