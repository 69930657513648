import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListQrCodeBatchComponent } from './list-qr-code-batch/list-qr-code-batch.component';
import { AddQrCodeBatchComponent } from './add-qr-code-batch/add-qr-code-batch.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    ListQrCodeBatchComponent,
    AddQrCodeBatchComponent
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule
  ]
})
export class QRCodesModule { }
