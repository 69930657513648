
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';

import { filter } from 'rxjs/operators';
import { map } from 'rxjs';
import { RouterModule } from '@angular/router';
import * as feather from 'feather-icons';
import { Title } from '@angular/platform-browser';
import { AppService } from '../../../app.service'

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs;
  public title : string

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router, private appService: AppService) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET || route.outlet === 'investigation'))
      .subscribe(route => {
        let snapshot = this.router.routerState.snapshot;
        let title = route.snapshot.data['title'];
        let parent = undefined;
        let child = undefined;
        let arrBreadcrumbs = route.snapshot?.data['breadcrumb'] ? route.snapshot?.data['breadcrumb'] : route.parent?.snapshot?.data['breadcrumb'];
        let parentLink = route.snapshot?.data['breadcrumbParentLink'] ? route.snapshot?.data['breadcrumbParentLink'] : route.parent?.snapshot?.data['breadcrumbParentLink'];;
        if(! Array.isArray(arrBreadcrumbs) ){
          parent = undefined
          child = arrBreadcrumbs;
      }
      else{
        if( arrBreadcrumbs.length == 2){
          parent = arrBreadcrumbs[0]
          child = arrBreadcrumbs[1];
        }
        else{
          child = arrBreadcrumbs[1];
        }
      }
        this.breadcrumbs = {};
        this.title = title;
        if( this.title?.startsWith(":")){
          this.appService.getTitle().subscribe(appTitle => this.title = appTitle);
        }
        this.breadcrumbs = {
          "parentBreadcrumb": parent,
          "childBreadcrumb": child,
          "parentLink" : parentLink
        }
      });
  }

  ngOnInit() {  
    setTimeout(() => {
      feather.replace();
    });
  }

  ngOnDestroy() {  }

}
