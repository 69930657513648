<div class="row">
    <div class="col-md-12 col-xl-12">
        <div class="card">
            <div class="card-header b-l-primary border-3">
                <button style="float: right;" type="button" class="btn btn-primary m-r-25" (click)="addNewContractor();$event.preventDefault()"><i class="p-r-5 fa fa-plus"></i><span><strong>Add New Contractor</strong></span></button>
            </div>
            <div class="card-body">
                <div #tableWrapper style="padding-left:15px;padding-right:15px;">
                    <ngx-datatable
                        #DatatableComponent
                        class="ngx-datatable content-table bootstrap fixed-header virtualized"
                        [loadingIndicator]="loading"
                        [columnMode]="'force'"
                        [headerHeight]="bShowFilters ? 105 : 50"
                        [footerHeight]="50"
                        [rowHeight]="65"
                        [externalPaging]="true"
                        [externalSorting]="true"
                        [count]="page.count"
                        [offset]="page.offset"
                        [limit]="page.limit"
                        [sortType]="'single'"
                        [reorderable]="true"
                        (page)="pageCallback($event)"
                        (sort)="sortCallback($event)"
                        [sorts]="[{prop: 'id', dir: 'desc'}]"
                        (activate)="onActivate($event)"
                        [scrollbarH]="true"
                        [rows]="items"
                        >
                        <ngx-datatable-column prop="id" name="ID" [width]="10" [resizeable]="false">
                            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <strong>{{row.id}}</strong>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column prop="name" name="Name" [width]="30" [resizeable]="true">
                            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <strong>{{row.name}}</strong>
                            </ng-template>
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                                <span class="datatable-header-cell-wrapper">
                                  <span class="datatable-header-cell-label" (click)="sort()">{{'Contractor Name' | translate
                                    }}</span>
                                </span>
                                <div class="filter-background w-100">
                                  <input [ngClass]="{'d-none':!bShowFilters}" (change)="contractorName = $event.target.value"
                                  [value]="contractorName" type="text" class="form-control w-100" placeholder="Press ↵ when done" (keydown.enter)="contractorName=$event.target.value;applyFilterAfterDelay()">
                                </div>
                              </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column prop="mobileNumber" name="Mobile Number" [width]="30" [resizeable]="true">
                            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <strong>{{row.mobileNumber}}</strong>
                            </ng-template>
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                                <span class="datatable-header-cell-wrapper">
                                  <span class="datatable-header-cell-label" (click)="sort()">{{'Mobile Number' | translate
                                    }}</span>
                                </span>
                                <div class="filter-background w-100">
                                  <input [ngClass]="{'d-none':!bShowFilters}" (change)="mobileNumber = $event.target.value"
                                  [value]="mobileNumber" type="text" class="form-control w-100" placeholder="Press ↵ when done" (keydown.enter)="mobileNumber=$event.target.value;applyFilterAfterDelay()">
                                </div>
                              </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column prop="bankAccountNumber" name="Bank Account Number" [width]="30" [resizeable]="true">
                            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <strong>{{row.bankAccountNumber}}</strong>
                            </ng-template>
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                                <span class="datatable-header-cell-wrapper">
                                  <span class="datatable-header-cell-label" (click)="sort()">{{'Bank Account Number' | translate
                                    }}</span>
                                </span>
                                <div class="filter-background w-100">
                                  <input [ngClass]="{'d-none':!bShowFilters}" (change)="bankAccountNumber = $event.target.value"
                                  [value]="bankAccountNumber" type="text" class="form-control w-100" placeholder="Press ↵ when done" (keydown.enter)="bankAccountNumber=$event.target.value;applyFilterAfterDelay()">
                                </div>
                              </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column prop="ifscCode" name="IFSC Code" [width]="30" [resizeable]="true">
                            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <strong>{{row.ifscCode}}</strong>
                            </ng-template>
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                                <span class="datatable-header-cell-wrapper">
                                  <span class="datatable-header-cell-label" (click)="sort()">{{'IFSC Code' | translate
                                    }}</span>
                                </span>
                                <div class="filter-background w-100">
                                  <input [ngClass]="{'d-none':!bShowFilters}" (change)="ifscCode = $event.target.value"
                                  [value]="ifscCode" type="text" class="form-control w-100" placeholder="Press ↵ when done" (keydown.enter)="ifscCode=$event.target.value;applyFilterAfterDelay()">
                                </div>
                              </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column prop="upiId" name="UPI ID" [width]="30" [resizeable]="true">
                            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <strong>{{row.upiId}}</strong>
                            </ng-template>
                            <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                                <span class="datatable-header-cell-wrapper">
                                  <span class="datatable-header-cell-label" (click)="sort()">{{'UPI ID' | translate
                                    }}</span>
                                </span>
                                <div class="filter-background w-100">
                                  <input [ngClass]="{'d-none':!bShowFilters}" (change)="upiId = $event.target.value"
                                  [value]="upiId" type="text" class="form-control w-100" placeholder="Press ↵ when done" (keydown.enter)="upiId=$event.target.value;applyFilterAfterDelay()">
                                </div>
                              </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column prop="verified" name="Verified?" [width]="30" [resizeable]="true">
                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                <div class="checkbox-container">
                                    <input disabled type="checkbox" [checked]="row.verified"/>
                                </div>
                              </ng-template>
                              <ng-template let-column="column" ngx-datatable-header-template let-sort="sortFn">
                                <span class="datatable-header-cell-wrapper">
                                  <span class="datatable-header-cell-label" (click)="sort()">{{'Verified' | translate }}</span>
                                </span>
                                <div class="filter-background w-100">
                                  <select [ngClass]="{'d-none':!bShowFilters}" class="form-control"
                                    (change)="verifiedCustomer = $event.target.value; applyFilter();"
                                    style="text-align: center;" [value]="verifiedCustomer">
                                    <option value="all">{{'All' | translate}}</option>
                                    <option value="verified">{{'Verified' | translate}}</option>
                                    <option value="unverified">{{'Unverified' | translate}}</option>
                                  </select>
                                </div>
                              </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column prop="createdAt" name="Created" [width]="30" [resizeable]="true">
                            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <strong>{{row.createdAt}}</strong>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column prop="updatedAt" name="Last Updated" [width]="30" [resizeable]="true">
                            <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
                                <strong>{{row.updatedAt}}</strong>
                            </ng-template>
                        </ngx-datatable-column>
                    
                        <ngx-datatable-footer>
                            <ng-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount"
                              let-curPage="curPage" let-offset="offset" ngx-datatable-footer-template>
                              <div class="controls">
                                <div>
                                  <label class="datatable-body-cell-label " for="per-page">
                                    {{'Items Per Page' | translate}}
                                  </label>
                                  <select id="items-per-page" class="form-control items-per-page"
                                    (change)="onLimitChange($event.target.value)" [value]="page.limit"
                                    style="display:inline-block;width: 50px;text-align: center;">
                                    <option *ngFor="let option of pageLimitOptions" [value]="option.value">
                                      {{ option.value }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <app-datatable-pager (download)="exportAs($event)" [page]="curPage" [visiblePagesCount]="3"
                                [size]="pageSize" [count]="rowCount" [hidden]="false" (showFilter)="showFilters()"
                                (clearFilter)="clearFilter()" [filtersShowing]="bShowFilters" [filterApplied]="bFilterApplied"
                                (change)="onPageChange($event)">
                              </app-datatable-pager>
                            </ng-template>
                          </ngx-datatable-footer>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
</div>
