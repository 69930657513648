import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Product, QRCodeBatch } from 'src/app/types/types';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-add-qr-code-batch',
  templateUrl: './add-qr-code-batch.component.html',
  styleUrls: ['./add-qr-code-batch.component.scss']
})
export class AddQrCodeBatchComponent implements OnInit {
  
  qRCodeBatch: QRCodeBatch = undefined;

  @Output() qRCodeBatchEntry: EventEmitter<QRCodeBatch> = new EventEmitter();

  form: FormGroup;

  products : Product[] = [];

  qrCodes = ''

  usedForPrinting : FormControl;

  
  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder,private http: HttpClient,private toastrService: ToastrService) { }

  ngOnInit(): void {
    
    this.loadProducts()
    if( this.qRCodeBatch ){
      this.form = this.fb.group({
        id:this.qRCodeBatch.id,
        batchName: [this.qRCodeBatch.batchName,[Validators.required]],
        numberOfCodes:[this.qRCodeBatch.numberOfCodes,[Validators.required]],
        chatBotNumber:[this.qRCodeBatch.chatBotNumber,[Validators.required, Validators.maxLength(10),Validators.minLength(10)]],
        productId:[this.qRCodeBatch.product.id,[Validators.required]],
        usedForPrinting:[this.qRCodeBatch.used]
      });
      const qrCodes = this.qRCodeBatch.qrcodes.map(a => `https://wa.me/91${this.form.controls.chatBotNumber.value}?text=${a.qrCode}`)
      this.qrCodes = qrCodes.join("\n")
      this.usedForPrinting = this.form.get('usedForPrinting') as FormControl
    }
    else{
      this.form = this.fb.group({
        batchName: ['',[Validators.required]],
        numberOfCodes:['',[Validators.required, Validators.min(1)]],
        chatBotNumber:['',[Validators.required]], 
        productId:['0',[Validators.required]]
      });
    }
  }

  loadProducts(){
    this.http.get<Product[]>(`${environment.serverUrl}/product`).subscribe({
      next:(response:any) => {
        this.products = response.data
      }
    })
  }
  onSubmit(){
  
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    let postUrl = `${environment.serverUrl}/qrcode-batch/`
    if( this.form.value.id != undefined){
      postUrl = `${environment.serverUrl}/qrcode-batch/${this.form.value.id}`
    }
    
    this.http.post(postUrl, JSON.stringify(this.form.value)).subscribe({
          next: (qrCodeBatch:QRCodeBatch) => {
            if( !this.qRCodeBatch )
              alert('QRCode Batch added successfully!')
            else{
              alert('QRCode Batch updated successfully!')
              this.activeModal.close('Success')
            }
            this.qRCodeBatchEntry.emit(qrCodeBatch);
            const qrCodes = qrCodeBatch.qrcodes.map(a => `https://wa.me/91${this.form.controls.chatBotNumber.value}?text=${a.qrCode}`)
            this.qrCodes = qrCodes.join("\n")
          },
          error: (e) => {
            console.error(e);
            //this.showMessage("error",`Adding new QRCode Batch failed. Message:${e.message}`);
          },
          complete: () => console.info('complete')
        });
  }

  copyQRCodes(){
    
    navigator.clipboard.writeText(this.qrCodes).then(
      function(){
          alert("QR Codes Copied to clipboard");
      })
    .catch(
       function() {
          alert("err");
    });

  }
}
