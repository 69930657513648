<div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Closed')"></button>
</div>
<form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="card-body">
        <div *ngIf="qRCodeBatch == undefined else edit_product" ><div class="ribbon ribbon-primary">Add New QRCode Batch</div></div>
        <ng-template #edit_product><div class="ribbon ribbon-primary">Edit QRCode Batch</div></ng-template>
        <div class="row">
            <div class="col-sm-12 col-xl-12 form-group m-form__group">
                <div class="card shadow shadow-showcase">
                    <div class="card-body row" style="margin-top:30px;">
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label> QRCode Batch Name</label>
                            <span class="text-danger">*</span>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="batchName" type="text" class="form-control" placeholder="Name of the QRCode Batch" required>
                            </div>
                            <div *ngIf="form.controls.batchName.invalid && (form.controls.batchName.dirty || form.controls.batchName.touched)" class="text text-danger">
                                <div *ngIf="form.controls.batchName.errors.required">
                                    QRCode Batch is required
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label >Number of QR Codes to generate</label>
                            <span class="text-danger">*</span>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="numberOfCodes" type="number" class="form-control" placeholder="Number of Codes to generate">
                            </div>
                            <div *ngIf="form.controls.numberOfCodes.invalid && (form.controls.numberOfCodes.dirty || form.controls.numberOfCodes.touched)" class="text text-danger">
                                <div *ngIf="form.controls.numberOfCodes.errors.required">
                                    Number of QR Codes is required
                                </div>
                                <div *ngIf="form.controls.numberOfCodes.errors.min">
                                    Number of QR Codes must be greater than 0
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label >Chat Bot Mobile Number (Without +91)</label>
                            <span class="text-danger">*</span>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="chatBotNumber" type="number" class="form-control" placeholder="Chat Bot Mobile Number">
                            </div>
                            <div *ngIf="form.controls.chatBotNumber.invalid && (form.controls.chatBotNumber.dirty || form.controls.chatBotNumber.touched)" class="text text-danger">
                                <div *ngIf="form.controls.chatBotNumber.errors.required">
                                    Chat Bot Mobile Number is required
                                </div>
                                <div *ngIf="form.controls.chatBotNumber.errors.minlength">
                                    Chat Bot Mobile Number should be 10 digits
                                </div>
                                <div *ngIf="form.controls.chatBotNumber.errors.maxlength">
                                    Chat Bot Mobile Number should be 10 digits
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label style="width:100%">Select Product
                            </label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-binary"></i></span>
                                </div>
                                <select  formControlName="productId" class="form-control digits">  
                                    <option value="0">--Select--</option>  
                                    <option *ngFor="let product of products"  
                                            value={{product.id}}>  
                                        {{product.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="qRCodeBatch" class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label style="width:100%">Used For Prinitng
                            </label>
                            <div class="input-group">
                                <input formControlName="usedForPrinting" (change)="$event.target.checked ? usedForPrinting.patchValue(1) : usedForPrinting.patchValue(0)" type="checkbox">
                            </div>
                        </div>
                    </div>
                    <div class="card-footer" style="text-align:right;">
                        <button *ngIf="!qRCodeBatch" type="submit" class="btn btn-primary me-1">Add QRCode Batch</button>
                        <button *ngIf="qRCodeBatch" type="submit" class="btn btn-primary me-1">Save QRCode Batch</button>
                        <button type="button" class="btn btn-light" (click)="activeModal.dismiss('Closed')">Cancel</button>
                    </div>
                </div>
                <div class="col-sm-12 col-xl-12 form-group m-form__group">
                    <div class="card shadow shadow-showcase">
                        <div class="card-body row" style="margin-top:30px;">
                            <div class="col-sm-12 col-xl-12 form-group m-form__group">
                                <label>Generated QRCodes</label>
                                <div class="input-group">
                                    <textarea disabled style="width: 100%;border: 1px solid #777;" [value]='qrCodes' rows="30" cols="120"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer" style="text-align:right;">
                            <button type="button" class="btn btn-light" (click)="copyQRCodes()">Copy QR Codes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>