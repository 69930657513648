<div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Closed')"></button>
</div>
<form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="card-body">
        <div *ngIf="redeemRequest == undefined else edit_product" ><div class="ribbon ribbon-primary">Add New Redeem Request</div></div>
        <ng-template #edit_product><div class="ribbon ribbon-primary">Edit Redeem Request</div></ng-template>
        <div class="row">
            <div class="col-sm-12 col-xl-12 form-group m-form__group">
                <div class="card shadow shadow-showcase">
                    <div class="card-body row" style="margin-top:30px;">
                        <div class="col-sm-6 col-xl-4 form-group m-form__group">
                            <label><strong>Redeem Request #</strong></label>
                            <div class="input-group">
                                <label>{{redeemRequest.uuid}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-4 form-group m-form__group">
                            <label><strong> QR Code</strong></label>
                            <div class="input-group">
                                <label>{{redeemRequest.qrCode}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-4 form-group m-form__group">
                            <label><strong>Customer Name</strong></label>
                            <div class="input-group">
                                <label>{{redeemRequest.customer?.name}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-4 form-group m-form__group">
                            <label><strong>Mobile Number</strong></label>
                            <div class="input-group">
                                <label>{{redeemRequest.customer?.mobileNumber}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-4 form-group m-form__group">
                            <label><strong>Request Date</strong></label>
                            <div class="input-group">
                                <label>{{redeemRequest.requestDate}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-4 form-group m-form__group">
                            <label><strong>Request Status</strong></label>
                            <div class="input-group">
                                <label>{{redeemRequest.requestStatus}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-4 form-group m-form__group">
                            <label><strong>Bank Account Number</strong></label>
                            <div class="input-group">
                                <label>{{redeemRequest.customer.bankAccountNumber}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-4 form-group m-form__group">
                            <label><strong>IFSC Code</strong></label>
                            <div class="input-group">
                                <label>{{redeemRequest.customer.ifscCode}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-4 form-group m-form__group">
                            <label><strong>UPI Id</strong></label>
                            <div class="input-group">
                                <label>{{redeemRequest.customer.upiId}}</label>
                            </div>
                        </div>

                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label>Payment Status</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <select  formControlName="paymentStatus" class="form-control digits">  
                                    <option value="Open">Not Paid</option>  
                                    <option value="Paid">Paid</option>  
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label>Payment Date</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="paymentDate" type="date" class="form-control" placeholder="Payment Date" required>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label>Transaction Number</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="transactionNumber" type="text" class="form-control" placeholder="Transaction Number" required>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xl-6 form-group m-form__group">
                            <label>Remarks</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="icofont icofont-user"></i></span>
                                </div>
                                <input autofocus formControlName="remarks" type="text" class="form-control" placeholder="Remarks" required>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer" style="text-align:right;">
                        <button type="submit" class="btn btn-primary me-1">Save Request</button>
                        <button type="button" class="btn btn-light" (click)="activeModal.dismiss('Closed')">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>