<div class="page-wrapper">
	<div class="auth-bg-video">
		<video id="bgvid" playsinline="" autoplay muted loop="">
			<!--source src="assets/video/auth-bg.mp4" type="video/mp4"-->
		</video>
		<div class="authentication-box">
			<div class="card mt-4">
				<div class="card-body">
					<div class="text-center">
						<div class="text-center"><img [ngStyle]="{'width': '200px','margin-bottom':'30px'}" src="assets/images/flyt-logo.png" alt=""></div>
						<h4>LOGIN</h4>
					</div>
					<form class="theme-form" [formGroup]="loginForm" *ngIf="true">
						<div class="form-group">
							<label class="col-form-label pt-0">Username</label>
							<input class="form-control" formControlName="email" type="email" required="">
							<div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
								class="text text-danger mt-1">
								Username is required
							</div>
							<div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
								class="text text-danger mt-1">
								Invalid Email
							</div>
						</div>
						<div class="form-group">
							<label class="col-form-label">Password</label>
							<input class="form-control" type="password" formControlName="password" required="">
							<div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
								class="text text-danger mt-1">
								Password is required
							</div>
						</div>
						<div class="checkbox p-0">
							<input id="checkbox1" type="checkbox">
							<label for="checkbox1">Remember me</label>
						</div>
						<div class="alert alert-danger" *ngIf="errorMessage">
							{{errorMessage}}
						 </div>
						<div class="form-group row mt-3 mb-0">
							<button class="btn btn-primary btn-block btn-lg"
								[class.loader--text]="authService.showLoader"
								[disabled]="!loginForm.valid || authService.showLoader" (click)="login(loginForm.value)"
								type="submit"><span>{{ authService.showLoader ? '' : 'Login' }}</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>