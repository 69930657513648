import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class UploadFilesService {

  private baseUrl = `${environment.serverUrl}/file`;

  constructor(private http: HttpClient) { }

  upload(file: File, component:string, componentFileNumber:string, parentFileNumber:string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    
    formData.append('component', component);
    formData.append('componentFileNumber', componentFileNumber);
    formData.append('parentFileNumber', parentFileNumber);
    formData.append('file', file);

    const req = new HttpRequest('POST', `${this.baseUrl}/upload`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  getFiles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/files`);
  }

}
