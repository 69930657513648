import { HttpClient, HttpContext } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../../../app.service';
import { Chat, RedeemRequest, IS_UPLOAD_REQUEST } from '../../../types/types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-enquiry-details',
  templateUrl: './redeem-request-details.component.html',
  styleUrls: ['./redeem-request-details.component.scss']
})
export class RedeemRequestDetailsComponent implements OnInit {

  @Output() redeemRequestEntry: EventEmitter<RedeemRequest> = new EventEmitter();

  environment = environment
  redeemRequest: RedeemRequest = undefined

  form: FormGroup;

  private _message = new Subject<string>();
  
  alertError : boolean = false;

  public ClassicEditor = ClassicEditor;
  
  message : string = '';

  chats: Chat[]

  constructor(private route: ActivatedRoute,private fb: FormBuilder,protected http: HttpClient,
    private toastrService: ToastrService, protected router: Router,private breadcrumbService: BreadcrumbService, 
    private appService: AppService,public activeModal: NgbActiveModal) { }

  ngOnInit(): void {

    this.form = this.fb.group({
      id: [''],
      paymentDate: [''],
      transactionNumber: [''],
      paymentStatus: [],
      remarks: [''],
    });
    this.loadRedeemRequest();
  }

  loadRedeemRequest(){
    this.http.get<RedeemRequest>(`${environment.serverUrl}/redeem-request/${this.redeemRequest.uuid}`)
    .subscribe({
      next: (redeemRequest) => {
        this.redeemRequest = redeemRequest;
        this.loadFormData();
        this.chats = JSON.parse(this.redeemRequest.log || "[]" )
        this.chats.map((chat) => {

          chat.value = chat.value.replace(new RegExp("\n", 'g'), "<br><br>")
          return chat
        })

      },
      error: (e) => {
        console.error(e);
        this.toastrService.error(`Error`, 'RedeemRequest Not Found',{ closeButton:true,disableTimeOut:true,positionClass: 'toast-top-full-width',enableHtml:true });
      }
    });
  }

  loadFormData(){
    this.form = this.fb.group({
      id: [this.redeemRequest.id],
      paymentDate: [this.redeemRequest.paymentDate],
      transactionNumber: [this.redeemRequest.transactionNumber],
      paymentStatus: [this.redeemRequest.requestStatus],
      remarks: [this.redeemRequest.remarks],
    });
  }

  onSubmit(){
    const formData: FormData = new FormData();    

    for (const field in this.form.controls) { // 'field' is a string
      const control = this.form.get(field); // 'control' is a FormControl  
      formData.append(field,control.value)
    }

    this.http.post(`${environment.serverUrl}/redeem-request/${this.redeemRequest.id}`, formData,{
      context:new HttpContext().set(IS_UPLOAD_REQUEST, true)
    }).subscribe({
          next: (v:RedeemRequest) => {
            this.activeModal.close('Success')
            this.toastrService.success('Enquiry Updated successfully!','Success',{ timeOut: 5000,positionClass: 'toast-top-full-width' });
            this.redeemRequestEntry.emit(v);
          },
          error: (e) => {
            console.error(e);
            this.showMessage("error",`Updating Enquiry Failed. Message:${e.message}`);
          },
          complete: () => console.info('complete')
        });
  }

  showMessage(type, message){
    if( type == "error" ){
      this.alertError = true;
    }
    this._message.next(message);
  }
}
