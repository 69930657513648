import { Component, OnInit } from '@angular/core';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NgbOffcanvas, OffcanvasDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../../app.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';

interface AnyResponse {
  data: any[];
  count: number;
}

@Component({
  selector: 'app-list-base',
  templateUrl: './list-base.component.html',
  styleUrls: ['./list-base.component.scss']
})
export class ListBaseComponent<T> implements OnInit {

  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: 'id',
    orderDir: 'asc'
  };

  public readonly pageLimitOptions = [
    {value: 10},
    {value: 25},
    {value: 50},
    {value: 100},
  ];

  protected loading: boolean = false;
  protected environment = environment
  protected items: T[];

  bFilterApplied = false

  constructor(protected http: HttpClient, protected breadcrumbService: BreadcrumbService, protected appService: AppService,
    protected offcanvasService: NgbOffcanvas, protected router: Router, protected route:ActivatedRoute) { }

  ngOnInit(): void {

  }

  protected get loadingUrl(){
    return "";
  }

  protected get exportUrl(){
    return ``;
  }

  protected get exportFileName(){
    return ``;
  }

  public onLimitChange(limit: any): void {
    this.page.offset = 0;
    this.page.limit = limit;
    (document.getElementsByClassName('items-per-page')[0] as HTMLInputElement).value = limit;
    this.reloadTable();
  }

  protected pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.reloadTable();
  }

  protected sortCallback(sortInfo: { sorts: { dir: string, prop: string }[], column: {}, prevValue: string, newValue: string }) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.page.offset = 0;
    this.reloadTable();
  }

  onPageChange(event){
    this.page.offset = event.page - 1;
    this.reloadTable();
  }

  protected reloadTable() {
    this.loading = true;
    const skip = this.page.offset * this.page.limit;
    let params = new HttpParams()
    .set('order', `${this.page.orderBy}`)
    .set('orderDir', `${this.page.orderDir}`)
    .set('skip', `${skip}`)
    .set('take', `${this.page.limit}`);
      
    params = this.populateParamsForQuery(params);

    const modified = params.keys().filter(key =>{
      if(key !== "order" && key !== "orderDir" && key !== "skip" && key !== "take") return true
    })
    if( modified.length > 0){
      this.bFilterApplied = true
    }
    else{
      this.bFilterApplied = false
    }

    this.http.get<AnyResponse>(this.loadingUrl, { params })
    .subscribe({
      next: (itemsResponse: AnyResponse) => {
        this.items = itemsResponse.data;
        this.page.count = itemsResponse.count;
        this.loading = false;  
      },
      error: (e) => {
        console.error(e);
        this.showMessage("error",`Loading Evidences failed. Message:${e.message}`);
        this.loading = false;  
      }
    });
  }

  protected populateParamsForQuery(params: HttpParams): HttpParams{
    return params;
  }

  applyFilter(){
    this.page.offset = 0;
    this.reloadTable();
  }

  clearFilter(){
    this.reloadTable();
  }

  protected showMessage(type, message){
    
  }

  protected downloadPDF(){
    let params = new HttpParams()
    .set('orderBy', `${this.page.orderBy}`)
    .set('orderDir', `${this.page.orderDir}`)
    .set('page', `${this.page.offset}`)
    .set('size', Number.MAX_VALUE);

    params = this.populateParamsForQuery(params);
    params = params.set('type', `PDF`)
    const httpOptions : Object = {
      headers: new HttpHeaders({'Accept':'application/pdf'}),
      params: params,
      responseType: 'blob'
    };
    this.http.get<Blob>(this.exportUrl,httpOptions)
    .subscribe(blob => {
      console.log(blob)
      saveAs(new Blob([blob], { type: 'application/pdf;charset=utf-8' }), `${this.exportFileName}.pdf`)
     } )
  }

  protected downloadXLS(){
    let params = new HttpParams()
    .set('orderBy', `${this.page.orderBy}`)
    .set('orderDir', `${this.page.orderDir}`)
    .set('page', `${this.page.offset}`)
    .set('size', 1000000);

    params = this.populateParamsForQuery(params);

    params = params.set('type', `XLS`)
    const httpOptions : Object = {
      headers: new HttpHeaders({'Accept':'application/vnd.ms-excel'}),
      params: params,
      responseType: 'blob'
    };
    this.http.get<Blob>(this.exportUrl,httpOptions)
    .subscribe(blob => {
      console.log(blob)
      saveAs(new Blob([blob], { type: 'vnd.ms-excel;charset=utf-8' }), `${this.exportFileName}.xlsx`)
     } )
  }

  protected downloadCSV(){
    let params = new HttpParams()
    .set('orderBy', `${this.page.orderBy}`)
    .set('orderDir', `${this.page.orderDir}`)
    .set('page', `${this.page.offset}`)
    .set('size', Number.MAX_VALUE);

    params = this.populateParamsForQuery(params);
    params = params.set('type', `CSV`)

    const httpOptions : Object = {
      headers: new HttpHeaders({'Accept':'text/csv'}),
      params: params,
      responseType: 'blob'
    };
    this.http.get<Blob>(this.exportUrl,httpOptions)
    .subscribe(blob => {
      console.log(blob)
      saveAs(new Blob([blob], { type: 'text/csv;charset=utf-8' }), `${this.exportFileName}.csv`)
     } )
  }

  protected exportAs($event){
    if( $event == "PDF"){
      this.downloadPDF();
    }
    else if( $event == "XLS"){
      this.downloadXLS();
    }
    else if( $event == "CSV"){
      this.downloadCSV();
    }
  }

}
