import { HttpClient } from "@angular/common/http";
import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from "@angular/core";
import { Comment } from "../../../../types/types";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { environment } from "../../../../../environments/environment";
import Helper from '../../../../helper/Helper';

@Component({
  selector: "app-comment-box",
  templateUrl: "./comment-box.component.html",
  styleUrls: ["./comment-box.component.scss"]
})
export class CommentBoxComponent implements OnInit {
  
  @Input() comment : Comment;
  @Input() post : any;
  @Output() add = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @ViewChild('commentsEditor') commentsEditor: any;
  
  public ClassicEditor = ClassicEditor;
  value: string;
  constructor( private httpClient: HttpClient) {}

  ngOnInit() {
    ClassicEditor.defaultConfig = Helper.classicEditorDefaultConfig();

  }

  onAdd() {
    if (this.commentsEditor && this.commentsEditor.editorInstance) {
      console.log(this.commentsEditor.editorInstance.getData())
      const data = {
        comment : this.commentsEditor.editorInstance.getData(),
        entryId : this.post?.id,
        reply : (this.comment) ? true : false,
        replyToCommentId : (this.comment) ? this.comment.id : 0,
      }
      this.httpClient.post<Comment>(`${environment.serverUrl}/comments/occurrence/${this.post?.id}`,data)
      .subscribe({
        next: (comments) => {
          this.add.next()
          this.commentsEditor.editorInstance.setData("");
        },
        error: (e) => {
          console.error(e);
          //Show alert
        }
    });
    }
  }

  onCancel(){
    this.cancel.next();
  }
}
