<ul>
	<li>
		<div class="d-flex mediaClass">
			<img src='{{environment.serverUrl}}/user/profile-pic/{{comment?.postedBy?.id}}' alt="User Image">
		<div class="flex-grow-1" style="position:relative">
			<div class="row">
			<div class="col-xl-12">
				<h6 class="mt-0" style="padding-top: 5px;color:#000">{{comment?.postedBy?.firstName}}<span> | {{comment.lastModified}} </span></h6>
			</div>
			</div>
			<p style="margin-bottom: 20px;" [innerHtml]='comment.text'></p>
			<a *ngIf="!isEditing" style="cursor: pointer;padding:6px;position: absolute;right: 0;bottom: 0;background-color:#0f4064;color:white" (click)="replyClick()">Reply</a>
			<app-comment-box *ngIf="isEditing" [post]="post" [comment]="comment" (cancel)="onCancel($event)" (add)="onCommentAdded($event)"></app-comment-box>
		</div>
		
		</div>
		<hr>
		<app-comment [post]="post" *ngFor="let c of comment?.replies" (add)="onCommentAdded($event)" [comment]="c"></app-comment>
	</li>
</ul>