import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Comment } from "../../../../types/types";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-comment",
  templateUrl: "./comment.component.html",
  styleUrls: ["./comment.component.scss"]
})
export class CommentComponent implements OnInit {
  @Input() comment: Comment;
  @Input() post : any;
  @Output() add = new EventEmitter<void>();
  
  environment = environment
  isEditing = false;
  constructor() {}

  ngOnInit() {}

  replyClick() {
    this.isEditing = !this.isEditing;
  }

  onCommentAdded($event) {
    this.isEditing = false;
    this.add.next();
  }

  onCancel($event){
    this.isEditing = false;
  }

}
